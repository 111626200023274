<script>
    const menu = [
        { id: '0', link: 'mailto:aarongiuessepe.real@gmail.com', text: 'Email' },
		{ id: '1', link: 'https://linkedin.com/in/aaronreal', text: 'LinkedIn' },
    ];
</script>

<header class="header">
	<div class="name">
		<p>{$$props.name}</p>
	</div>

	<div class="menu">
		{#each menu as { id, link, text }}
			<p>
				<a href="{link}" target="_blank" rel="noopener noreferrer">{text}</a>
			</p>
		{/each}
	</div>
</header>