<script>
    import Header from './components/Header.svelte';
    import Hero from './components/Hero.svelte';
    import Projects from './components/Projects.svelte';
    import Footer from './components/Footer.svelte';

    const user = {
    	name: 'Aaron Real',
    	heroText: 'Front-end developer based in Philippines.',
    	heroDesc: 'With several years of experience on digital marketing, developing web applications and managing e-commerce businesses at different companies.<br><br>I specialize on creating effective online presence for various clients.',
    };

</script>

<Header {...user} />

<main class="main">

	<Hero heroText={user.heroText} heroDesc={user.heroDesc} />
	<Projects />
</main>

<Footer {...user} />