<script>
	export let heroText;
	export let heroDesc;
</script>

<section class="hero">
	<h1 class="title">
		{@html heroText}
	</h1>
	<p class="desc">
		{@html heroDesc}
	</p>
</section>
