<script>
    const projects = [
        { 
        	id: '0', 
        	title: 'Dafabet', 
        	link: 'https://www.dafabet.com/', 
        	img: './images/dafabet.jpg', 
        	date: 'Sep 2014',
        	company: 'w/ BTI',
        	companyLink: 'https://www.bayviewtechnology.com/',
        },
        { 
        	id: '1', 
        	title: 'Envirosite', 
        	link: 'https://www.envirositecorp.com/', 
        	img: './images/envirosite.jpg', 
        	date: 'Jul 2018',
        	company: 'w/ ADEC',
        	companyLink: 'https://www.adec-innovations.com',
        },
        { 
            id: '2', 
            title: 'CleanChain', 
            link: 'https://www.cleanchain.com/', 
            img: './images/cleanchain.jpg',
            date: 'Sep 2018',
            company: 'w/ ADEC',
            companyLink: 'https://www.adec-innovations.com',
        },
        { 
        	id: '3', 
        	title: 'Consulate of Samoa in Philippines', 
        	link: 'https://www.samoa.ph/', 
        	img: './images/samoa.jpg',
        	date: 'Oct 2018',
        	company: 'w/ ADEC',
        	companyLink: 'https://www.adec-innovations.com',
        },
        { 
        	id: '4', 
        	title: 'ESG Solutions', 
        	link: 'https://www.esg.adec-innovations.com/', 
        	img: './images/esg.jpg',
        	date: 'Dec 2018',
        	company: 'w/ ADEC',
        	companyLink: 'https://www.adec-innovations.com',
        },
        { 
        	id: '5', 
        	title: 'ADEC Innovations', 
        	link: 'https://www.adec-innovations.com', 
        	img: './images/adec.jpg',
        	date: 'Jan 2019',
        	company: 'w/ ADEC',
        	companyLink: 'https://www.adec-innovations.com',
        },
        { 
        	id: '6', 
        	title: 'Gala Spins', 
        	link: 'https://www.galaspins.com/', 
        	img: './images/galaspins.jpg',
        	date: 'Sep 2019',
        	company: 'w/ Entain',
        	companyLink: 'https://www.entaingroup.com/',
        },
        { 
        	id: '7', 
        	title: 'Gala Bingo', 
        	link: 'https://www.galabingo.com/', 
        	img: './images/galabingo.jpg',
        	date: 'Dec 2019',
        	company: 'w/ Entain',
        	companyLink: 'https://www.entaingroup.com/',
        },
		{ 
        	id: '8', 
        	title: 'Sumitomo Drive', 
        	link: 'https://us.sumitomodrive.com/', 
        	img: './images/sumitomodrive.jpg',
        	date: 'Aug 2021',
        	company: 'w/ MRM',
        	companyLink: 'https://www.mrm.com/',
        },
		{ 
        	id: '9', 
        	title: 'Saudi Vision 2030', 
        	link: 'https://www.vision2030.gov.sa/', 
        	img: './images/vision.jpg',
        	date: 'May 2023',
        	company: 'w/ Merkle',
        	companyLink: 'https://www.merkle.com/',
        },
		{ 
        	id: '10', 
        	title: 'Bellagroup', 
        	link: 'https://www.bellagroup.dk/', 
        	img: './images/bellagroup.webp',
        	date: 'Oct 2023',
        	company: 'w/ Merkle',
        	companyLink: 'https://www.merkle.com/',
        },
		{ 
        	id: '11', 
        	title: 'Copenhagen Gaming Week', 
        	link: 'https://www.copenhagengamingweek.dk/', 
        	img: './images/cgw.jpg',
        	date: 'Jan 2024',
        	company: 'w/ Merkle',
        	companyLink: 'https://www.merkle.com/',
        },
		{ 
        	id: '12', 
        	title: 'Husqvarna', 
        	link: 'https://www.husqvarna.com/us/', 
        	img: './images/husqvarna.jpg',
        	date: 'July 2024',
        	company: 'w/ Merkle',
        	companyLink: 'https://www.merkle.com/',
        },
    ];

    const linkTitle = 'See it live';
</script>

<section class="projects">
	{#each projects.slice().reverse() as { id, title, link, img, date, company, companyLink }}
		<div class="project-item">
			<div class="thumbnail" style="background-image: url('{img}')">
			</div>
			<div class="desc">
				<div class="text">
					<h2 class="title">
						{@html title}
					</h2>
					<div class="date-container ml-6">
						<p class="date">
							{@html date}
						</p>
						<p class="company">
							<a href="{companyLink}" target="_blank" rel="noopener noreferrer">{@html company}</a>
						</p>
					</div>
				</div>
				<div class="link mt-auto">
					<p class="w-32 flex items-center">
						<a href="{link}" target="_blank" rel="noopener noreferrer">{@html linkTitle}</a>
					</p>
				</div>
			</div>
		</div>
	{/each}
</section>